
import React from 'react';
import { Qfunctions } from './Qfunctions';
import { generateRandomString } from '../../../../utils/general';
import { Form, Row } from 'react-bootstrap';

export const renderActiveField = (fields, setFields, activeField, setActiveField, setRegret, Questions, customHTML, setCustomHTML, setFileUploading, version) => {

    if(customHTML) return;

    let field = fields[activeField];
    let answer = field.answer;

    switch (field.type){

        case 'function' :

            Qfunctions[version][field.function](fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);

            break;

        case 'text' :

                return (
                    <div>
                        <Form.Control name={generateRandomString(15)} autoComplete="off" placeholder={ field.placeholder ? field.placeholder  : '' } type={field.type} value={ field.answer ? field.answer : '' }  onChange={ (e) => {
                            field.answer = e.target.value;
                            setFields({...fields, field});
                        } } />
                    </div>
                );
        
        case 'hidden' :

            return (
                <div>
                    <Form.Control name={generateRandomString(15)} autoComplete="off" placeholder={ field.placeholder ? field.placeholder  : '' } type={field.type} value={ field.answer ? field.answer : '' }  onChange={ (e) => {
                        field.answer = e.target.value;
                        setFields({...fields, field});
                    } } />
                </div>
            );

        case 'number' :

                return (
                    <div>
                        <Form.Control name={generateRandomString(15)} autoComplete="off" placeholder={ field.placeholder ? field.placeholder  : '' } type={field.type} value={ field.answer ? field.answer : '' }  onChange={ (e) => {
                            field.answer = e.target.value;
                            setFields({...fields, field});
                        } } />
                    </div>
                );
        
        case 'date' :

                return (
                    <div>
                        <Form.Control name={generateRandomString(15)} autoComplete="off" placeholder={ field.placeholder ? field.placeholder  : '' } type={field.type} value={ field.answer ? field.answer : '' }  onChange={ (e) => {
                            field.answer = e.target.value;
                            setFields({...fields, field});
                        } } />
                    </div>
                );

        case 'radio' :

            return(
                <div>
                    <Row>
                        {
                            field.options.map(option => (
                                <div key={option} className="col-3">
                                    <Form.Check inline type="radio">
                                        <Form.Check.Input id={option} name={generateRandomString(15)} value={option} checked={ field.answer === option ? true : false } onChange={ (e) => {
                                            field.answer = e.target.value;
                                            setFields({...fields, field});
                                        } } />
                                        <Form.Check.Label htmlFor={option} className="pl-1" >{`${option}`}</Form.Check.Label>
                                    </Form.Check>
                                </div>
                            ))
                        }
                    </Row>
                </div>
            );
        
        case 'checkbox' :

            return(
                <div>
                    <Row>
                        {
                            field.options.map(option => (
                                <div key={option} className="col-3">
                                    <Form.Check inline type="checkbox">
                                        <Form.Check.Input id={option} name={generateRandomString(15)} value={option} checked={ answer.includes(option) } onChange={ (e) => {

                                            let item = e.target.value;

                                            if( answer.includes(item) ){
                                                let index = answer.indexOf(item);
                                                if (index !== -1){
                                                    answer.splice(index, 1);
                                                }
                                            }
                                            else {
                                                answer.push(item);
                                            }
                                        
                                            setFields({...fields, field});
                                            //console.log('field.answer: ', field.answer);
                                        } } />
                                        <Form.Check.Label htmlFor={option} className="pl-1" >{`${option}`}</Form.Check.Label>
                                    </Form.Check>
                                </div>
                            ))
                        }
                    </Row>
                </div>
            );
        
        case 'checkbox-6' :

            return(
                <div>
                    <Row>
                        {
                            field.options.map(option => (
                                <div key={option} className="col-6">
                                    <Form.Check type="checkbox">
                                        <Form.Check.Input id={option} name={generateRandomString(15)} value={option} checked={ answer.includes(option) } onChange={ (e) => {

                                            let item = e.target.value;

                                            if( answer.includes(item) ){
                                                let index = answer.indexOf(item);
                                                if (index !== -1){
                                                    answer.splice(index, 1);
                                                }
                                            }
                                            else {
                                                answer.push(item);
                                            }
                                        
                                            setFields({...fields, field});
                                            //console.log('field.answer: ', field.answer);
                                        } } />
                                        <Form.Check.Label htmlFor={option} className="pl-1" >{`${option}`}</Form.Check.Label>
                                    </Form.Check>
                                </div>
                            ))
                        }
                    </Row>
                </div>
            );
        
        case 'checkbox-12' :

            return(
                <div>
                    <Row>
                        {
                            field.options.map(option => (
                                <div key={option} className="col-12">
                                    <Form.Check type="checkbox">
                                        <Form.Check.Input id={option} name={generateRandomString(15)} value={option} checked={ answer.includes(option) } onChange={ (e) => {

                                            let item = e.target.value;

                                            if( answer.includes(item) ){
                                                let index = answer.indexOf(item);
                                                if (index !== -1){
                                                    answer.splice(index, 1);
                                                }
                                            }
                                            else {
                                                answer.push(item);
                                            }
                                        
                                            setFields({...fields, field});
                                            //console.log('field.answer: ', field.answer);
                                        } } />
                                        <Form.Check.Label htmlFor={option} className="pl-1" >{`${option}`}</Form.Check.Label>
                                    </Form.Check>
                                </div>
                            ))
                        }
                    </Row>
                </div>
            );

        case 'select' :

            //console.log('SELECT ANSWER: ', answer);
                        
            return (
                <div>
                    <Form.Control as="select" name={generateRandomString(15)} autoComplete="off" defaultValue={answer} onChange={ (e) => {
                        field.answer = e.target.value;
                        setFields({...fields, field});
                    } }>
                        <option value=''>Select...</option>
                        {
                            field.options.map(option => (
                                <option value={option} key={option}>{option}</option>
                            ))
                        }
                    </Form.Control>
                </div>
            );

        default :
            console.log('We should never get here!');
    }
    
}