import React from 'react';
import { Questions } from './inc/Questions';
import { Strings } from './inc/Strings';
import { renderActiveField } from './inc/Fields';
import { submitToServer } from '../../../utils/general';
import { Row, Col, Button, Spinner, ProgressBar } from 'react-bootstrap';

function HealthSurvey(parent) {

    const renderActiveBody = () => {
        
        let field = fields[activeField];
        let bodyMarkup = field.body ? field.body : '';
        
        // Add horizontal rule to break the question/desc from answer
        if(field.body){
            bodyMarkup += `<hr style="margin: 1.5em 0;" />`;
        }

        return <div dangerouslySetInnerHTML={{__html: bodyMarkup}} />;
    }

    const renderRegret = () => {

        // Submit Application (this saves progress up to the last field where the failure occurred)
        // update - this is causing an endless loop submitting when applicant is regretted and why its been commented out 
        //submitApplication();

        let field = fields[activeField];
        let message = !locked ? field.regret.message : Strings.locked;
        
        return (
            <div dangerouslySetInnerHTML={{__html: message}} />
        );
    }

    /**
     * If we ever need this in production we need to redo it recursively
     */
    const handlePrevClick = () => {

        let prev = activeField ? activeField -1 : 0;
        let field = fields[prev];

        if(field.type === 'function'){
            console.log('Field type is a function go back another 1!');
            prev = prev -1;
        }

        setActiveField(prev); 
    }

    const handleNextClick = () => {

        // 1. Validate current question
        let status = handleValidation();  
        if (!status) return;

        // Submit Application (this saves progress)
        submitApplication();

        // 2. Check current question for regret
        let field = fields[activeField];

        if(field.regret){

            let comparison  = field.regret.comparison;
            let condition   = field.regret.condition;
            let answer      = field.answer;

            let regret;

            if (condition === 'includes' )
            {   
                // Check if answer is string or array to handle the includes() method properly
                if( Array.isArray(answer) )
                {
                    // Array
                    regret = answer.find( (item) => {
                        return comparison.includes(item);
                    });
                    
                }
                else {
                    // String
                    regret = comparison.includes(answer);
                }
            }
            else
            {
                regret = eval(`"${answer}" ${condition} "${comparison}"`);
            }

            if(regret)
            {
                // lock application form
                let deferred = true;
                lockApplication(deferred);

                setRegret(true);

                return;
            }
        }

        // 3a. Remove any custom HTML from previous view
        setCustomHTML(false);

        // 3b. check dependencies for next question(s)
        let next = activeField +1;

        // No more questions for applicant, they have reached the end!
        if(fields[next] === undefined){

            // lock application form
            let deferred = false;
            lockApplication(deferred);

            setFormCompleted(true);
            setCustomHTML(Strings.success);
            return;
        }

        if(fields[next].dependency)
        {   
            // The next field has a dependency so keep going until we find the next question that doesn't
            nextApplicableField(next);
        }
        else
        {
            // No dependency on next field so go to it
            setActiveField(next); 
        }
            
    }

    const handleValidation = () => {

        const field = fields[activeField];
        const type = field.validation;

         // Check for empty/null answers first
        if( field.answer === Object(field.answer) )
        {
            const keys = Object.keys(field.answer);
            
            let empty = keys.some( item => {

                // Handle uploads a bit differently checking if they are required based on the question validation parameter
                if(item === 'upload')
                {
                    if(field.validation[item] === 'required')
                    {   
                        // required
                        return (field.answer[item] === '' || field.answer[item] === null);
                    }
                    else {
                        // not required
                        return false;
                    }
                }

                if(field.validation && field.validation[item] !== 'none')
                {
                    return (field.answer[item] === '' || field.answer[item] === null);
                }
                else
                {
                    return false;
                }
            });

            // checkboxes where at least one selection is required
            if(keys.length === 0 && type === 'selection-required') empty = true;

            if(empty)
            {
                alert('Please first answer the current question(s) before proceeding to the next one!');
                return false;
            }

            return true;
        }
        else if(field.answer === '' || field.answer === null )
        {
            alert('Please first answer the current question(s) before proceeding to the next one!');
            return false;
        }
        else {

            if(type)
            {
                switch (type) {

                    case 'required' :
                        
                        return (field.answer === '') ? false : true;
                    
                    case 'selection-required' :
                        console.log('field.answer: ', field.answer);
                        return (field.answer === '') ? false : true;

                    case 'decimal' :

                        let result = /^[0-9]+(\.[0-9]+)?$/.test(field.answer);

                        if(!result){
                            alert('Invalid format... Only integer & periods allowed!');
                            return false;
                        }

                        return true;

                    default :
                        console.log('default case for handleValidation()');

                }
            }

            return true;
        }
    }

    const nextApplicableField = (next) => {

        const nextField = fields[next];

        // No more questions for applicant, they have reached the end!
        if(nextField === undefined){

            // lock application form
            
            let deferred = false;
            lockApplication(deferred);

            setFormCompleted(true);
            setCustomHTML(Strings.success);
            return;
        }

        if(nextField.dependency){

            const answer = fields[nextField.dependency.field].answer;
            const condition = nextField.dependency.condition;
            const comparison = nextField.dependency.comparison;

            let applicable;

            if(condition === 'includes'){
                applicable = answer.includes(comparison);
            }
            else{
                applicable = eval(`"${answer}" ${condition} "${comparison}"`);
            }


            // The next question PASSED the criteria (by users previous answers) so go to it
            if(applicable){
                setActiveField(next);
            }
            else {
                // The next question FAILS the criteria (by users previous answers) so go to the following question until we find a match or there are no dependencies
                nextApplicableField(next + 1);
                //return;
            }
        }
        else
        {
            setActiveField(next);
        }
    }

    const submitApplication = (e) => {

        if(inProgress){
            console.log('Application syncing currently in progress...');
            return;
        }
        else
            console.log('Syncing application with backend API!');

        const endpoint = `${process.env.REACT_APP_API_URL}/public/api/forms/${parent.props.form_id}`;
        const data = {    
            api_token   : parent.props.api_token,
            form_id     : parent.props.form_id,
            form        : fields
       }

       setInProgress(true);

        return submitToServer( 'PUT', endpoint, data ).then( response => {
            if(response.status !== 200){
                handleFormSubmissionErrors(response.status)
            }
           setInProgress(false);
        });
    }

    const lockApplication = (deferred) => {        
        
        const endpoint = `${process.env.REACT_APP_API_URL}/public/api/form/lock`;
        
        const data = {    
            api_token   : parent.props.api_token,
            id          : parent.props.form_id,
            deferred    : deferred ? 1 : 0,
            locked      : 1
        }
       
       setInProgress(true);

       return submitToServer( 'POST', endpoint, data ).then( response => {
            
            if(response.status !== 200)
            {
                const message = `Locking/Deferring Applicant (#${parent.props.form_id}) \n
                    Endpoint: ${endpoint}\n
                    Request: ${JSON.stringify(data)}\n
                    Response: ${response.status} - ${response.statusText}\n
                `;
                
                handleFormSubmissionErrors(response.status, 'ALERT', message)
            }

           setInProgress(false);

        });
    }

    const updateProgressBar = () => {

        let totalQuestions = Object.keys(fields).length - 3;
        let percentageValue = (activeField/totalQuestions) * 100;

        setProgress(percentageValue);
    }

    console.log('parent.props.version : ', parent.props.version );
    //console.log('parent.props.form : ', parent.props.form );

    const updateConnectivitytatus = (event) => {
        setOffline(navigator.onLine ? false : true)
    }

    const handleFormSubmissionErrors = (statusCode = null, loglevel = false, message = false) => {

        const formId = parent.props.form_id;

        if(!formError){
            
            if(statusCode){
                loglevel = loglevel ? loglevel : 'ALERT';
                message = message ? message : `log error with known code (#${formId})`;
            }
            else {
                setFormError(true); // prevent from logging every time
                loglevel = loglevel ? loglevel : 'CRITICAL';
                message = message ? message : `no status code, indicitive of CORS and likely a 413 code (#${formId})`;
            }

            const endpoint = `${process.env.REACT_APP_LOG_URL}/api/logs/add`;
            const data = {    
                level   : loglevel,
                message : message,
                form : formId
            }

            return submitToServer( 'POST', endpoint, data).then( response => {
                console.log('Remote Logs Response: ', response);
            });

        }
    }

    const [activeField, setActiveField] = React.useState( parent.props.form && parent.props.form.active ? parent.props.form.active : 0 ); // change 0 to question index to test specific questions (and clear all form data from the database)
    const [fields, setFields] = React.useState(parent.props.form ? parent.props.form : {
        ...Questions.versions[parent.props.version],
        version: parent.props.version
    });
    const [locked, setLocked] = React.useState(parent.props.locked ? parent.props.locked : false);
    const [regret, setRegret] = React.useState(parent.props.locked ? parent.props.locked : false);
    const [customHTML, setCustomHTML] = React.useState(false);
    const [formCompleted, setFormCompleted] = React.useState(false);
    const [fileUploading, setFileUploading] = React.useState(false);
    const [inProgress, setInProgress] = React.useState(false);
    const [progress, setProgress] = React.useState(0);
    const [offline, setOffline] = React.useState(navigator.onLine ? false : true);
    const [formError, setFormError] = React.useState(false);

    React.useEffect( () => {
       
        window.addEventListener('online', updateConnectivitytatus);
        window.addEventListener('offline', updateConnectivitytatus);

        return () => {
            window.removeEventListener('online', updateConnectivitytatus);
            window.removeEventListener('offline', updateConnectivitytatus);
        }

    }, []);

    React.useEffect( () => {
        //console.log('fields Updated: ', fields); // <------ testing purposes only, can be removed!
        //console.log(JSON.stringify(fields));
    }, [fields]);

    // Update the activeField field in the questions object when activeField changes
    React.useEffect( () => {

        let active = activeField;
        
        updateProgressBar();
        setFields({...fields, active});

    }, [activeField]);

    return (
        <Row>
            {   
                (!regret && !formCompleted) &&
                    <Col sm={{ span: '8', offset: '2' }} className="form-tabs">
                        { 
                            //Object.keys(fields).length 
                            fields[activeField].question
                        }
                    </Col>
            }

            <Col sm={{ span: '8', offset: '2' }} className="form-copy">
                
                <p>&nbsp;</p>

                {
                    offline && 
                        <>
                            <div className="offline-notice alert alert-warning">
                                <strong>You are not connected to the internet!</strong><br />
                                <small>Once your connectivity is restored you will be able to resume.</small>
                            </div>
                        </>
                }
                
                {   
                    // Display customHTML for form completed only
                    !regret && formCompleted && customHTML ? <div dangerouslySetInnerHTML={{__html: customHTML}} /> : '' 
                }

                { 
                    // Display customHTML for everything else
                    !regret && !formCompleted && customHTML ? customHTML : ''
                }

                { !regret && !formCompleted ? renderActiveBody() : '' }
                
                { regret ? renderRegret() : renderActiveField(fields, setFields, activeField, setActiveField, setRegret, Questions.versions[parent.props.version], customHTML, setCustomHTML, setFileUploading, parent.props.version) }

                { 
                    fileUploading &&
                    <div>
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Uploading...</span>
                        </Spinner>
                        &nbsp; Uploading....
                    </div>
                }
                
                <p>&nbsp;</p>

                { 
                    (!regret && !formCompleted) &&
                    <>
                        <div>
                            <Button
                                variant="primary"
                                onClick={
                                    (e) => { handleNextClick() }
                                }
                                disabled={offline}
                            >
                                Next
                            </Button>

                            &nbsp;&nbsp;&nbsp;

                            {
                                inProgress && 
                                <Spinner animation="border" role="status" className="question float-right">
                                    <span className="sr-only">Saving...</span>
                                </Spinner>
                            }
                        </div>

                        <div style={{marginTop: '25px' }}>
                        <ProgressBar striped variant="warning" now={progress} label={`${Math.floor(progress)}%`} />
                        </div>
                    </>
                }

            </Col>

        </Row>
    );
}

export default HealthSurvey;