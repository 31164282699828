export async function submitToServer( method, endpoint, data, headers = {} ) {
   
   let params = {
      credentials: 'same-origin',
      headers: {
         'Content-type' : 'application/json',
         'Accept'       : 'application/json',
         ...headers
      }
   };

   if(method !== 'GET'){
      params = {
         ...params,
         method: method,
         body: JSON.stringify(data)
      }
   }

   try { 
      
      const response = await fetch(endpoint, params);
      const {status, statusText} = response;
 
      let responseJson = await response.json();
      responseJson.status = status;
      responseJson.statusText = statusText;
      
      return responseJson;

   } catch(error) {
      
      return error;
   }
}

/**
 * Time/Date Helper functions
 */

export const today = new Date(), month = today.getMonth() +1;
export const currentDate  = today.getFullYear() + '-' + (month < 10 ? '0' + month : month )  + '-' + today.getDate();


/**
 * Generate Random/Unique string
 */
export const generateRandomString = (length) => {
   
   let result = '';
   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
   
   for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
   }

   return result;
}

/**
 * Function to handle file uploads within the form
 */
export const handleFileUpload = (e, field, fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading, callback) => {

    setFileUploading(true);

    let file = e.target.files[0]; // FileList object
    let fileSize = file.size / 1024 / 1024; // in MiB

    if (fileSize > 1) {
        alert('Sorry, this file is too big (max 1MB)! Please move on to the next question. You can manually email this document to the SABMR after completing your form.');
        setFileUploading(false);
        e.target.value = null;
        return;
    }
    
     // Only process image files.
    //  if (!file.type.match('image.*')) {
    //     continue;
    //   }

    setTimeout(() => {
        
        let reader = new FileReader();

        // Closure to capture the file information.
        reader.onload = ( () => {
            return function(e) {
                setFileUploading(false);
                // console.log('----------> result: ');
                // console.log(e.target.result);

                field.answer['upload'] = e.target.result;
                setFields({...fields, field});

                callback && callback(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
            };
        })(file);

        // Read in the image file as a data URL.
        reader.readAsDataURL(file);

    }, 0); // 5000 for testing
}