import { Questions_1_0_2 } from './versions/1.0.2/Questions';
import { Questions_1_0_3 } from './versions/1.0.3/Questions';

/**
 * Validation, use 'none' to ignore validation on a field.
 */
export const Questions = {
    'version': process.env.REACT_APP_QUESTIONS_VERSION,
    'active' : 0,
    'versions': {
        '1'     : Questions_1_0_2, // Legacy support
        '1.0.1' : Questions_1_0_2, // Legacy support
        '1.0.2' : Questions_1_0_2,
        '1.0.3' : Questions_1_0_3,
    }
}