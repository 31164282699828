import React from 'react';
import HealthSurvey from '../views/form/HealthSurvey';
import RetrieveForm from '../views/RetrieveForm';
import { submitToServer } from '../../utils/general';
import logo from '../../assets/logo.png';

/**
 * http://localhost:3000/?drm_code=DRM5211096OL&api_token=YKiq5K69Fd74oWjpzVtloH85F89Qa2W4Jo3hdhHJjlWiOFUbSjCug4kHoGBf&form_id=2
 */

class Body extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			drm_code 	: false,
			api_token 	: false,
			form_id		: false,
			loading		: false,
			name		: '',
			surname 	: '',
			email		: '',
			mobile 		: '',
		};
	}

	// Prevent mutating state directly by mounting first
	componentDidMount() {
		this.checkParamsAndSessionStorage();
	}

	// setLocalStorage(data){

	// 	let fields = Object.keys(data.form);
		
	// 	fields.map(field => {
			
	// 		let answer = data.form[field].answer;

	// 		// Check if the answer is an object
	// 		if( answer === Object(answer) ){

	// 			let fields = Object.keys(answer);
	// 			console.log('fields ======> ', fields);

	// 		}
	// 		else {
				
	// 			if(answer){
	// 				sessionStorage.setItem(`field_${field}`, data.form[field].answer);
	// 			}
	// 		}

	// 	});
	// }

	checkParamsAndSessionStorage = () => {

		let url     = new URL(window.location);
		let params  = new URLSearchParams(url.search);

		if( params.get('fetch') ) {

			if(!params.get('api_token')){
				console.log('No	API key present in query string!');
			}

			const apiToken = params.get('api_token');
			const formId = params.get('form_id');
			const position = params.get('position') ? params.get('position') : false;

			// Show loading
			this.setState({ 'loading' : true });
			
			setTimeout( async () => {
				// Fetch form data
				return submitToServer(
					'POST',
					`${process.env.REACT_APP_API_URL}/public/api/forms/${formId}`,
					{ api_token: apiToken }
				)
				.then( data => {

					let savedFormData = data.form;
					console.log('Saved form data: ', savedFormData);

					// Take user to specific point in the form if set
					if(position) {
						savedFormData.active = parseInt(position);
					}

					this.setState({
						...this.state,
						...data,
						'api_token'	: apiToken,
						'form_id'		: formId,
						'position'	: position,
						'loading' 	: false,
						'form'			: savedFormData,
						'locked' 		: data.locked,
						'version'	: savedFormData && savedFormData.version ? savedFormData.version : process.env.REACT_APP_QUESTIONS_VERSION
					});
				});

			}, 0); // 2000 for testing
		}

		

	}

	render() {

		return(


			<div className="container mb-5 text-left">

				<div className="row mb-5">
						
					<div className="col-12 col-md-4">
						
					</div>
					
					<div className="col-12 col-md-4 text-center">
						<img src={logo} alt="Logo" className="img-fluid" />
					</div>
					
					<div className="col-12 col-md-4">
					</div>
	
				</div>

				{ 
					this.state.drm_code ? <HealthSurvey props={ this.state } /> : <RetrieveForm loading={this.state.loading} /> 
				}

				<div style={{
						position: 'fixed', bottom: '0', background: '#fff', right: '0', 
						padding: '6px 12px', fontSize: '0.85rem'
					}}
				>
					version {this.state.version}
				</div>

			</div>
		);
	}
}

export default Body;