import { Strings } from './strings';
import { currentDate } from '../../../../../../utils/general';

/**
 * Validation, use 'none' to ignore validation on a field.
 */
export const Questions_1_0_3 = {
    0 : {
        'question'  : 'Gender',
        'answer'    : '',
        'type'      : 'radio',
        'regret'    : false,
        'options'   : ['Male', 'Female'],
    },
    1 : {
        'question'    : 'What is your weight?',
        'answer'      : '',
        'type'        : 'text',
        'placeholder' : '85 (kg)',
        'validation'  : 'decimal',
        'regret'      : false,
    },
    2 : {
        'question'  : 'What is your height?',
        'answer'    : '',
        'type'      : 'text',
        'placeholder' : '1.63 (cm)',
        'validation' : 'decimal',
        'regret'    : false,
    },
    3 : {
        'question'  : 'BMI',
        'answer'    : '',
        'type'      : 'function',
        'function'  : 'calculateBmi',
        'regret' : {
            'condition'  : '<',
            'comparison' : 41,
            'message'    : Strings.regret.question[3]
        }
    },
    4 : {
        'question'  : 'Does your family agree to you donating stem cells?',
        'answer'    : '',
        'type'      : 'select',
        'options'   : [
            'Yes - My family supports my decision',
            'No - I need to discuss this with my family in more detail'
        ],
        'regret' : {
            'condition'  : '==',
            'comparison' : 'No - I need to discuss this with my family in more detail',
            'message'    : Strings.regret.question[4]
        }
    },
    5 : {
        'question'   : 'Have you ever been pregnant?',
        'answer'     : '',
        'type'      : 'radio',
        'options'    : ['Yes', 'No'],
        'dependency' : {
            'field'      : 0,
            'condition'  : '==',
            'comparison' : 'Female'
        }
    },
    6 : {
        'question'   : 'If so, specify the number of pregnancies?',
        'answer'     : '',
        'type'       : 'text',
        'dependency' : {
            'field'      : 5,
            'condition'  : '==',
            'comparison' : 'Yes'
        }
    },
    7 : {
        'question'   : 'Date of last pregnancy or currently pregnant?',
        'answer'     : '',
        'type'       : 'text',
        'dependency' : {
            'field'      : 5,
            'condition'  : '==',
            'comparison' : 'Yes'
        }
    },
    8 : {
        'question'   : 'Have you ever been tissue typed before?',
        'answer'     : '',
        'type'      : 'radio',
        'options'    : ['Yes', 'No'],
    },
    9 : {
        'question'   : 'Please provide details (Tissue Typing)',
        'answer'     : '',
        'type'       : 'text',
        'dependency' : {
            'field'      : 8,
            'condition'  : '==',
            'comparison' : 'Yes'
        }
    },
    10 : {
        'question'   : 'Have you ever tested positive for HIV?',
        'answer'     : '',
        'type'      : 'radio',
        'options'    : ['Yes', 'No'],
        'regret' : {
            'condition'  : '==',
            'comparison' : 'Yes',
            'message'    : Strings.regret.defaultMessage
        }
    },
    11 : {
        'question'  : 'Do you consider yourself or your partner at risk for HIV or other STD infections?',
        'answer'    : '',
        'type'      : 'text',
        'regret'    : false
    },
    12 : {
        'question'  : 'Do you have a first degree relative with a history of Acute Myeloid Leukaemia (AML) or Myelodysplastic Syndrome (MDS)?',
        'answer'    : '',
        'type'      : 'radio',
        'options'    : ['Yes', 'No'],
        'regret' : {
            'condition'  : '==',
            'comparison' : 'Yes',
            'message'    : Strings.regret.defaultMessage
        }
    },
    13 : {
        'question'  : 'Do you suffer from any auto-immune condition?',
        'body'      : '<p>Including Alopecia areata, Ankylosing spondylitis, Coeliac disease, Cold Agglutinin disease, Graves’ disease, Goodpasture’s syndrome, Hashimoto’s thyroiditis, Myasthenia gravis, Pernicious anaemia, Psoriasis, Raynaud’s disease, Reiter’s syndrome, Rheumatoid arthritis, Sarcoidosis, Scleroderma, Sjögren’s syndrome, Systemic Lupus Erythematosus (SLE), Ulcerative colitis or Crohn’s disease, Uveitis, Vasculitis and Vitiligo.</p>',
        'answer'    : '',
        'type'      : 'radio',
        'options'    : ['Yes', 'No'],
        'regret' : {
            'condition'  : '==',
            'comparison' : 'Yes',
            'message'    : Strings.regret.defaultMessage
        }
    },
    14 : {
        'question'   : 'Have you ever had any serious illness?',
        'body'       : '<p>Please select from the following list of options:</p>',
        'answer'     : [],
        'type'      : 'checkbox-6',
        'options'    : ['Cancer', 'Diabetes', 'Heart disease', 'Lung disease', 'Chest pains', 'Convulsions', 'Asthma', 'Shortness of breath',
            'Stroke', 'Kidney', 'Intestinal problems', 'Thyroid disease', 'Blood disease', 'Genetic disease', 'None'
        ],
        'regret' : {
            'condition'  : 'includes',
            'comparison' : ['Stroke', 'peanut'], //'Stroke',
            'message'    : Strings.regret.defaultMessage
        }
    },
    15 : {
        'question'   : 'Cancer',
        'body'       : 'Please provide details of condition, last date and duration of treatment.',
        'answer'     : '',
        'type'      : 'text',
        'dependency' : {
            'field'      : 14,
            'condition'  : 'includes',
            'comparison' : 'Cancer'
        }
    },
    16 : {
        'question'   : 'Diabetes',
        'body'       : 'Please provide details of condition, last date and duration of treatment.',
        'answer'     : '',
        'type'       : 'select',
        'options'    : [
            'Type 1',
            'Type 2 (Requires insulin to control)',
            'Type 2 (No insulin to control)',
            'Pre-diabetes (controlled by diet alone)'
        ],
        'dependency' : {
            'field'      : 14,
            'condition'  : 'includes',
            'comparison' : 'Diabetes'
        },
        'regret' : {
            'condition'  : 'includes',
            'comparison' : ['Type 1', 'Type 2 (Requires insulin to control)'],
            'message'    : Strings.regret.defaultMessage
        }
    },
    17 : {
        'question'   : 'Heart disease',
        'body'       : 'Please provide details of condition, last date and duration of treatment.',
        'answer'     : '',
        'type'      : 'text',
        'dependency' : {
            'field'      : 14,
            'condition'  : 'includes',
            'comparison' : 'Heart disease'
        }
    },
    18 : {
        'question'   : 'Lung disease',
        'body'       : 'Please provide details of condition, last date and duration of treatment.',
        'answer'     : '',
        'type'      : 'text',
        'dependency' : {
            'field'      : 14,
            'condition'  : 'includes',
            'comparison' : 'Lung disease'
        }
    },
    19 : {
        'question'   : 'Chest pains',
        'body'       : 'Please provide details of condition, last date and duration of treatment.',
        'answer'     : '',
        'type'      : 'text',
        'dependency' : {
            'field'      : 14,
            'condition'  : 'includes',
            'comparison' : 'Chest pains'
        }
    },
    20 : {
        'question'   : 'Convulsions',
        'body'       : 'Please provide details of condition, last date and duration of treatment.',
        'answer'     : '',
        'type'      : 'text',
        'dependency' : {
            'field'      : 14,
            'condition'  : 'includes',
            'comparison' : 'Convulsions'
        }
    },
    21 : {
        'question'   : 'Asthma',
        'answer'     : {
            'Date of last asthma attack'    : '',
            'Current Medication'            : '',
            'Duration of condition'         : ''
        },
        'validation' : {
            'date'          : '',
            'medication'    : '',
            'duration'      : ''
        },
        'type'      : 'function',
        'function'  : 'asthmaDetails',
        'dependency' : {
            'field'      : 14,
            'condition'  : 'includes',
            'comparison' : 'Asthma'
        }
    },
    22 : {
        'question'   : 'Shortness of breath',
        'body'       : 'Please provide details of condition, last date and duration of treatment.',
        'answer'     : '',
        'type'      : 'text',
        'dependency' : {
            'field'      : 14,
            'condition'  : 'includes',
            'comparison' : 'Shortness of breath'
        }
    },
    23 : {
        'question'   : 'Kidney',
        'answer'     : {
            'Details of condition'      : '',
            'Last date of treatment'    : '',
            'Duration of treatment'     : '',
            'Have you ever been considered for a kidney dialysis program?' : {
                'answer' : '',
                'detail' : ''
            },
            'Do your ankles swell up at the end of the day?' : {
                'answer' : '',
                'detail' : ''
            },
            'Do you get up more than once a night to pass urine?' : {
                'answer' : '',
                'detail' : ''
            }
        },
        'validation' : {
            'Details of condition'      : '',
            'Last date of treatment'    : '',
            'Duration of treatment'     : '',
            'Have you ever been considered for a kidney dialysis program?' : {
                'answer' : '',
                'detail' : ''
            },
            'Do your ankles swell up at the end of the day?' : {
                'answer' : '',
                'detail' : ''
            },
            'Do you get up more than once a night to pass urine?' : {
                'answer' : '',
                'detail' : ''
            }
        },
        'type'      : 'function',
        'function'  : 'kidneyDetails',
        'dependency' : {
            'field'      : 14,
            'condition'  : 'includes',
            'comparison' : 'Kidney'
        }
    },
    24 : {
        'question'   : 'Intestinal problems',
        'answer'     : {
            'Details of condition'      : '',
            'Last date of treatment'    : '',
            'Duration of treatment'     : '',
        },
        'validation' : {
            'Details of condition'      : '',
            'Last date of treatment'    : '',
            'Duration of treatment'     : '',
        },
        'type'      : 'function',
        'function'  : 'intestinalProblemsDetails',
        'dependency' : {
            'field'      : 14,
            'condition'  : 'includes',
            'comparison' : 'Intestinal problems'
        }
    },
    25 : {
        'question'   : 'Thyroid disease',
        'answer'     : {
            'Details of condition'      : '',
            'Last date of treatment'    : '',
            'Duration of treatment'     : '',
            'upload'                    : ''
        },
        'validation' : {
            'Details of condition'      : 'none',
            'Last date of treatment'    : 'none',
            'Duration of treatment'     : 'none',
            'upload'                    : '' // mark 'required' if compulsory
        },
        'type'      : 'function',
        'function'  : 'thyroidDiseaseDetails',
        'dependency' : {
            'field'      : 14,
            'condition'  : 'includes',
            'comparison' : 'Thyroid disease'
        }
    },
    26 : {
        'question'   : 'Blood disease',
        'answer'     : {
            'Details of condition'      : '',
            'Last date of treatment'    : '',
            'Duration of treatment'     : '',
        },
        'validation' : {
            'Details of condition'      : '',
            'Last date of treatment'    : '',
            'Duration of treatment'     : '',
        },
        'type'      : 'function',
        'function'  : 'bloodDiseaseDetails',
        'dependency' : {
            'field'      : 14,
            'condition'  : 'includes',
            'comparison' : 'Blood disease'
        }
    },
    27 : {
        'question'   : 'Genetic disease',
        'answer'     : {
            'Details of condition'      : '',
            'Last date of treatment'    : '',
            'Duration of treatment'     : '',
        },
        'validation' : {
            'Details of condition'      : '',
            'Last date of treatment'    : '',
            'Duration of treatment'     : '',
        },
        'type'      : 'function',
        'function'  : 'geneticDiseaseDetails',
        'dependency' : {
            'field'      : 14,
            'condition'  : 'includes',
            'comparison' : 'Genetic disease'
        }
    },
    28 : {
        'question'  : 'Have you had malaria symptoms in the last 3 years?',
        'answer'    : '',
        'type'      : 'radio',
        'options'    : ['Yes', 'No'],
    },
    29 : {
        'question'   : 'Malaria',
        'answer'     : {
            'Details of condition'      : '',
            'Last date of treatment'    : '',
            'Duration of treatment'     : '',
        },
        'validation' : {
            'Details of condition'      : '',
            'Last date of treatment'    : '',
            'Duration of treatment'     : '',
        },
        'type'      : 'function',
        'function'  : 'malariaDetails',
        'dependency' : {
            'field'      : 28,
            'condition'  : '===',
            'comparison' : 'Yes'
        }
    },
    30 : {
        'question'  : 'Have you had any operations in particular dura mater, brain, heart or major surgery?',
        'answer'    : '',
        'type'      : 'radio',
        'options'    : ['Yes', 'No'],
    },
    31 : {
        'question'   : 'Operations',
        'answer'     : {
            'Details of condition'      : '',
            'Last date of treatment'    : '',
            'Duration of treatment'     : '',
        },
        'validation' : {
            'Details of condition'      : '',
            'Last date of treatment'    : '',
            'Duration of treatment'     : '',
        },
        'type'      : 'function',
        'function'  : 'operationsDetails',
        'dependency' : {
            'field'      : 30,
            'condition'  : '===',
            'comparison' : 'Yes'
        }
    },
    32 : {
        'question'  : 'Have you ever received an organ transplant e.g. cornea, dura mater, kidney or bone marrow?',
        'answer'    : '',
        'type'      : 'select',
        'options'    : ['No', 'Yes (synthetic)', 'Yes (human product)'],
        'validation' : 'selection-required',
        'regret' : {
            'condition'  : '==',
            'comparison' : 'Yes (human product)',
            'message'    : Strings.regret.defaultMessage
        }
    },
    33 : {
        'question'  : 'Synthetic organ transplant - please provide more information',
        'answer'    : '',
        'type'      : 'text',
        'dependency' : {
            'field'      : 32,
            'condition'  : '===',
            'comparison' : 'Yes (synthetic)'
        }
    },
    34 : {
        'question'  : 'Have you ever had hepatitis B or C, jaundice or liver disease?',
        'answer'    : '',
        'type'      : 'radio',
        'options'    : ['Yes', 'No'],
    },
    35 : {
        'question'   : 'Hepatitis B or C, jaundice or liver disease Details',
        'answer'     : {
            'Details of condition'      : '',
            'Last date of treatment'    : '',
            'Duration of treatment'     : '',
        },
        'validation' : {
            'Details of condition'      : '',
            'Last date of treatment'    : '',
            'Duration of treatment'     : '',
        },
        'type'      : 'function',
        'function'  : 'hepatitisEtcDetails',
        'dependency' : {
            'field'      : 34,
            'condition'  : '===',
            'comparison' : 'Yes'
        }
    },
    36 : {
        'question'  : 'Have you ever taken any growth hormones including treatment for infertility or slimming?',
        'answer'    : '',
        'type'      : 'radio',
        'options'    : ['Yes', 'No'],
    },
    37 : {
        'question'   : 'Growth hormone Details',
        'answer'     : {
            'Details of condition'      : '',
            'Last date of treatment'    : '',
            'Duration of treatment'     : '',
        },
        'validation' : {
            'Details of condition'      : '',
            'Last date of treatment'    : '',
            'Duration of treatment'     : '',
        },
        'type'      : 'function',
        'function'  : 'growthHormoneDetails',
        'dependency' : {
            'field'      : 36,
            'condition'  : '===',
            'comparison' : 'Yes'
        }
    },
    38 : {
        'question'  : 'Have you ever taken any drugs not prescribed by a doctor including recreational and body building drugs?',
        'answer'    : '',
        'type'      : 'radio',
        'options'    : ['Yes', 'No'],
    },
    39 : {
        'question'   : 'Non-prescription Drug Details',
        'answer'     : {
            'Details of condition'      : '',
            'Last date of treatment'    : '',
            'Duration of treatment'     : '',
        },
        'validation' : {
            'Details of condition'      : '',
            'Last date of treatment'    : '',
            'Duration of treatment'     : '',
        },
        'type'      : 'function',
        'function'  : 'nonPrescriptionDetails',
        'dependency' : {
            'field'      : 38,
            'condition'  : '===',
            'comparison' : 'Yes'
        }
    },
    40 : {
        'question'  : 'Have you ever received a blood transfusion?',
        'answer'    : '',
        'type'      : 'radio',
        'options'   : ['Yes', 'No'],
    },
    41 : {
        'question'   : 'Blood transfusion Details',
        'answer'     : {
            'Details of condition'      : '',
            'Country of transfusion'    : '',
            'Year of transfusion'       : '',
        },
        'validation' : {
            'Details of condition'      : '',
            'Country of transfusion'    : '',
            'Year of transfusion'       : '',
        },
        'type'      : 'function',
        'function'  : 'bloodTransfusionDetails',
        'dependency' : {
            'field'      : 40,
            'condition'  : '===',
            'comparison' : 'Yes'
        }
    },
    42 : {
        'question'   : 'Do you have any hay fever or other allergies (food, material or medicine etc.)?',
        'answer'     : {
            'answer' : '',
            'detail' : ''
        },
        'validation' : {
            'answer' : '',
            'detail' : 'none'
        },
        'type'      : 'function',
        'function'  : 'allergyDetails'
    },
    43 : {
        'question'   : 'Have you ever been refused medical insurance/aid due to medical conditions?',
        'answer'     : {
            'answer' : '',
            'detail' : ''
        },
        'validation' : {
            'answer' : '',
            'detail' : 'none'
        },
        'type'      : 'function',
        'function'  : 'refusedMedicalDetails'
    },
    44 : {
        'question'  : 'Are you a regular blood donor?',
        'answer'    : '',
        'type'      : 'radio',
        'options'   : ['Yes', 'No'],
    },
    45 : {
        'question'   : 'Blood donor Details',
        'answer'     : {
            'card number'   : '',
            'upload'        : '',
        },
        'validation' : {
            'card number'   : '',
            'upload'        : '',
        },
        'type'      : 'function',
        'function'  : 'bloodDonorDetails',
        'dependency' : {
            'field'      : 44,
            'condition'  : '===',
            'comparison' : 'Yes'
        }
    },
    46 : {
        'question'  : 'Are you currently a platelet donor?',
        'body'      : '<p><small>A platelet transfusion can prevent major bleeding. Certain patients are dependent on receiving HLA-compatible platelets from HLA matched donors in the course of their treatment (chemotherapy, transplantation and other).</small></p>',
        'answer'    : '',
        'type'      : 'radio',
        'options'    : ['Yes', 'No']
    },
    47 : {
        'question'  : 'Are you willing to become a platelet donor?',
        'answer'    : '',
        'type'      : 'radio',
        'options'    : ['Yes', 'No'],
        'dependency' : {
            'field'      : 46,
            'condition'  : '===',
            'comparison' : 'No'
        }
    },
    48 : {
        'question'  : 'Consent',
        'body'      : '<p><small>I consent to my personal information being given to the South African National Blood Service (SANBS) and Western Cape Blood Service (WCBS)?</small><p>',
        'answer'    : '',
        'type'      : 'radio',
        'options'    : ['Yes', 'No']
    },
    49 : {
        'question'  : 'Health Segment Completed',
        'body'      : Strings.healthSegment,
        'answer'    : 'content-field',
        'type'      : 'hidden',
    },
    50 : {
        'question'  : 'Identification, passport or permit number',
        'answer'    : '',
        'type'      : 'text',
        'regret'    : false
    },
    51 : {
        'question'  : 'Date Of Birth',
        'answer'    : '',
        'type'      : 'date',
        'regret'    : false
    },
    52 : {
        'question'   : 'Ethnic Group',
        'body'       : '<p><small>To which ethnic group do you belong?</small></p>',
        'answer'     : [],
        'type'      : 'checkbox-12',
        'options'    : ['Asian/Indian', 'Black', 'Coloured', 'White', 'Other/Unknown'],
        'validation' : 'selection-required'
    },
    53 : {
        'question'   : 'Applicant Personal Information',
        'answer'     : {
            'Postal Address'                : '',
            'Postal Code'                   : '',
            'Mobile Number'                 : '',
            'Telephone Number (Home)'       : '',
            'Telephone Number (Business)'   : '',
        },
        'validation' : {
            'Postal Address'                : '',
            'Postal Code'                   : '',
            'Mobile Number'                 : '',
            'Telephone Number (Home)'       : 'none',
            'Telephone Number (Business)'   : 'none',
        },
        'type'      : 'function',
        'function'  : 'additionalPersonalInformation',
    },
    54 : {
        'question'   : 'Alternative Contact Information (Primary)',
        'answer'     : {
            'First Name'                    : '',
            'Last Name'                     : '',
            'Type of Relationship'          : '',
            'Mobile Number'                 : '',
            'Telephone Number (Home)'       : '',
            'Email Address'                 : '',
            'Postal Address'                : '',
            'Postal Code'                   : '',
        },
        'validation' : {
            'First Name'                    : '',
            'Last Name'                     : '',
            'Type of Relationship'          : '',
            'Mobile Number'                 : '',
            'Telephone Number (Home)'       : 'none',
            'Email Address'                 : '',
            'Postal Address'                : '',
            'Postal Code'                   : ''
        },
        'type'      : 'function',
        'function'  : 'alternativeContactInformationPrimary',
    },
    55 : {
        'question'   : 'Alternative Contact Information (Secondary)',
        'answer'     : {
            'First Name'                    : '',
            'Last Name'                     : '',
            'Type of Relationship'          : '',
            'Mobile Number'                 : '',
            'Telephone Number (Home)'       : '',
            'Email Address'                 : '',
            'Postal Address'                : '',
            'Postal Code'                   : ''
        },
        'validation' : {
            'First Name'                    : '',
            'Last Name'                     : '',
            'Type of Relationship'          : '',
            'Mobile Number'                 : '',
            'Telephone Number (Home)'       : 'none',
            'Email Address'                 : '',
            'Postal Address'                : '',
            'Postal Code'                   : ''
        },
        'type'      : 'function',
        'function'  : 'alternativeContactInformationSecondary',
    },
    56 : {
        'question'  : 'I agree to be contacted by SANBS or WCBS to donate platelets?',
        'answer'    : '',
        'type'      : 'radio',
        'options'    : ['Yes', 'No']
    },
    57 : {
        'question'   : 'How did you hear about the SABMR?',
        'body'       : '<p><small>Recruitment method in order to trace batch due / operational requirements</small></p>',
        'answer'     : [],
        'type'      : 'checkbox-12',
        'options'    : [
            'Donor Drive',
            'Call Centre',
            'Website',
            'Social Media',
            'Print Media',
            'Other',
            'Transfer from another Registry'
        ]
    },
    58 : {
        'question'   : 'Your Consent & Digital Signature',
        'answer'     : {
            'Name'          : '',
            'Surname'       : '',
            'Date'          : currentDate,
            'I confirm that I would like to Register as a blood stem cell donor' : '',
            'I confirm that I would like to receive a SABMR Donor Card as proof of registration' : '',
            'I agree to receiving marketing and general information and communication from SABMR' : '',
            'I agree to receiving newsletter from SABMR' : ''
        },
        'validation' : {
            'Name'          : '',
            'Surname'       : '',
            'Date'          : '',
            'I confirm that I would like to Register as a blood stem cell donor' : '',
            'I confirm that I would like to receive a SABMR Donor Card as proof of registration' : 'none',
            'I agree to receiving marketing and general information and communication from SABMR' : 'none',
            'I agree to receiving newsletter from SABMR' : 'none'
        },
        'type'      : 'function',
        'function'  : 'digitalSignature',
    }
}