import { Qfunctions_1_0_2 } from './versions/1.0.2/Qfunctions';
import { Qfunctions_1_0_3 } from './versions/1.0.3/Qfunctions';

/**
 * Validation, use 'none' to ignore validation on a field.
 */
export const Qfunctions = {
    '1'     : Qfunctions_1_0_2, // Legacy support
    '1.0.1' : Qfunctions_1_0_2, // Legacy support
    '1.0.2' : Qfunctions_1_0_3,
    '1.0.3' : Qfunctions_1_0_3,
}