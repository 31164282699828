import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';

class TopNavigation extends React.Component {

  render(){

    return(

      <Navbar collapseOnSelect expand="lg">
        
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        
        <Navbar.Collapse id="responsive-navbar-nav">
          
          <Nav className="mr-auto">
            <Nav.Link href="https://sabmr.co.za/" target="_blank">Website</Nav.Link>
            <Nav.Link href="https://sabmr.co.za/donate/" target="_blank">Donate</Nav.Link>
            <Nav.Link href="https://sabmr.co.za/frequently-asked-questions/" target="_blank">FAQ's</Nav.Link>
          </Nav>

          <Nav>
            <Nav.Link href="mailto:info@sabmr.co.za">info@sabmr.co.za</Nav.Link>
            <Nav.Link href="tel:+27 21 447 8638">+27 21 447 8638</Nav.Link>
          </Nav>

        </Navbar.Collapse>

      </Navbar>

    );
  }
}

export default TopNavigation;